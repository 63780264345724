import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import 'mburger-webcomponent'
import "mmenu-js";

import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});

import AOS from 'aos';
AOS.init({
  offset: 100, // offset (in px) from the original trigger point
  delay: 50, // values from 0 to 3000, with step 50ms
  duration: 500, // values from 0 to 3000, with step 50ms
  easing: 'ease-out-quad', // default easing for AOS animations
  once: true,
  mirror: false,
});

Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
 slidesPerView: 1,
 speed: 1500,
spaceBetween: '200px',
 loop: true,
 autoplay: {
  delay: 3000,
pauseOnMouseEnter: true
 },
   navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
});
